@charset "utf-8";
@use "sass:meta";
@use "../_sass/variables";

.star {
    position: absolute;
    left: -15px;
    top: 4px;
    width: 5px;
    height: 20px;
    display: inline-block;
    border-radius: 1px;
    opacity: 0.8;
}

.red {
    background-color: #f94f4f;
}

.orange {
    background-color: #ff7221;
}

.blue {
    background-color: #5e98da;
}

.green {
    background-color: #50e297;
}

.purple {
    background-color: #cd71e4;
}

.yellow {
    background-color: #ffd100;
}

#post-warning {
    color: #807e7a;
    background: #ffe8b1;
    padding: 15px;
    margin-bottom: 15px;
    font-size: 0.9em;
    border-radius: 2px;
}

#post-disclaimer {
    color: #807e7a;
    background: #ffd9b1;
    padding: 15px;
    margin-bottom: 15px;
    font-size: 0.9em;
    border-radius: 2px;
}

@include meta.load-css("../_sass/base");
@include meta.load-css("../_sass/layout");
@include meta.load-css("../_sass/syntax-highlighting");

// Import partials from `sass_dir` (defaults to `_sass`)
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;500&family=Literata:wght@300;400;600&display=swap);
