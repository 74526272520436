@use "variables";

@use "sass:color";
/**
 * Reset some basic elements
 */
blockquote,
body,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
ol,
p,
pre,
ul {
    margin: 0;
    padding: 0;
}

/**
 * Basic styling
 */
body {
    font: variables.$base-font-weight #{variables.$base-font-size}/#{variables.$base-line-height} variables.$base-font-family;
    color: variables.$text-color;
    background-color: variables.$background-color;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
%vertical-rhythm,
blockquote,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul {
    margin-bottom: calc(variables.$spacing-unit / 2);
}

/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
    margin: 25px 0;
}

/**
 * Figures
 */
figure>img {
    display: block;
}

figcaption {
    font-size: variables.$small-font-size;
}

/**
 * Lists
 */
ol,
ul {
    margin-left: variables.$spacing-unit;
}

li {

    >ol,
    >ul {
        margin-bottom: 0;
    }
}

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: variables.$header-font-family;
    font-weight: variables.$header-font-weight;
}

/**
 * Links
 */
a {
    color: variables.$link-color;
    text-decoration: underline;

    &:hover {
        text-decoration: underline;
    }
}

/**
 * Blockquotes
 */
blockquote {
    color: variables.$grey-color-dark;
    padding-left: calc(variables.$spacing-unit / 2);
    border-left: 2px solid variables.$grey-color-light;
    font-style: italic;
    font-size: variables.$small-font-size;

    > :last-child {
        margin-bottom: 0;
    }
}

/**
 * Code formatting
 */
code,
pre {
    background-color: #fcfcfc;
}

code {
    padding: 1px 5px;
    font-family: variables.$code-font-family;
    color: #f14e32;
}

pre {
    padding: 8px 12px;
    overflow-x: auto;
    // border-left: 2px solid $grey-color-light;

    >code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
        tab-size: 4;
        color: inherit;
    }
}

/**
 * Table
 */
table {
    width: 100%;
    max-width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
}

td,
th {
    padding: 0.5rem;
    line-height: inherit;
}

th {
    text-align: left;
    vertical-align: bottom;
    border-bottom: 2px solid variables.$grey-color-light;
}

td {
    vertical-align: top;
    border-bottom: 1px solid variables.$grey-color-light;
}

/**
 * Horizontal rule
 */
hr {
    border: none;
    border-top: 1px solid color.adjust(variables.$grey-color, $lightness: 25%);
    margin: variables.$spacing-unit auto;
}

/**
 * Clearfix
 */
%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
