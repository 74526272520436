@use "variables";

body::-webkit-scrollbar {
    display: none;
}

.page-content {
    margin: 1rem auto !important;
}

.smallcap a:hover {
    color: #f94f4f !important;
}

.smallcap span {
    text-decoration: none;
    letter-spacing: 2px;
    background-color: #f94f4f;
    color: #eee;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

a.featured {
    color: #b90404;
    text-decoration: none;
}

.time {
    display: block;
    margin-right: 20px;
    font-family: variables.$header-font-family;
    line-height: 1rem;
}

.ptitle {
    font-family: variables.$header-font-family;
    font-size: 1.1rem;
    max-width: variables.$max-width;
}

.share {
    margin-top: 50px;
    font-family: variables.$header-font-family;
}

.share a {
    text-decoration: none !important;
    margin-right: 12px;
    font-size: 0.9rem;
}

.share a i {
    margin-right: 2px;
}

.copy-url,
.reply-post,
.email-button {
    cursor: pointer;
    user-select: none;
    color: #4b5061;
}

.twitter-button {
    color: #1da1f2;
}

.subtitle-separator {
    margin: 0 3px;
}

.footnotes {
    font-size: 0.8em;
}

/**
 * Site header
 */
.site-header {
    border-bottom: 1px solid variables.$grey-color-light;
    margin-top: -2rem;
    max-width: variables.$max-width;
    letter-spacing: 0.5px;
    // p {
    //   font-size: $small-font-size;
    // }
}

.site-nav {
    font-family: variables.$header-font-family;
}

.lang {
    font-family: variables.$header-font-family;
    opacity: 0.4;
    font-size: 0.7em;
    padding-right: 5px;
}

.smallcap {
    //text-transform: lowercase;
    //font-variant: small-caps;
    font-size: 1.5rem;
    font-weight: bold;

    a,
    a:hover {
        text-decoration: none;
        letter-spacing: 2px;
        background-color: #3a3c3f;
        color: #eee;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }
}

/**
 * Page content
 */
.page-content {
    position: relative;
    padding: variables.$spacing-unit (
        variables.$spacing-unit * 0.25
    );
margin: 1rem;
box-sizing: border-box;
max-width: variables.$max-width;
}

/**
 * Pages
 */
.home {
    section+section {
        margin-top: variables.$spacing-unit;
        max-width: variables.$max-width;
    }
}

.post-list>li {
    margin-bottom: calc(variables.$spacing-unit / 4);
    list-style-type: none;
    margin-left: -2rem;

    a,
    a:hover {
        color: variables.$text-color;
        font-weight: 400;
        text-decoration: none;
    }

    time {
        font-size: variables.$small-font-size;
        color: variables.$grey-color;
    }

    @include variables.media-query(variables.$on-palm) {
        time {
            display: block;
            font-size: variables.$small-font-size;
        }
    }
}

.tag-title {
    color: variables.$link-color;
}

/**
 * Posts
 */
.post-header {
    margin-bottom: variables.$spacing-unit;
}

.post-title {
    font-size: 2rem;
    letter-spacing: -1px;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.post-meta {
    font-size: variables.$small-font-size;
    font-family: variables.$header-font-family;
    color: variables.$grey-color;

    a,
    a:visited {
        color: variables.$grey-color-dark;
    }
}

.post-content,
.queue-content {
    margin-bottom: variables.$spacing-unit;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 2rem;
    }

    h1,
    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.75rem;
    }

    h4 {
        font-size: 1.5rem;
    }

    h5,
    h6 {
        font-size: 1.25rem;
    }

    h2,
    h3,
    h4 {
        &:not(:first-child)::before {
            content: "";
            display: block;
            height: 10px;
            border-top: 1px dashed #a9a9a9;
            border-radius: 2px;
            margin-top: 2em;
            margin-bottom: 1em;
        }
    }
}

.copyright {
    margin-top: variables.$spacing-unit;
    font-size: variables.$small-font-size;

    p {
        color: variables.$grey-color;

        a,
        a:visited {
            color: variables.$grey-color-dark;
        }
    }
}
