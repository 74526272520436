@use "sass:color";
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Our variables
$base-font-family: "Literata", serif;
$header-font-family: "Alegreya Sans", sans-serif;
$code-font-family: Inconsolata, Monaco, Consolas, monospace;
$base-font-size: 16px;
$base-font-weight: 300;
$header-font-weight: 500;
$small-font-size: 0.9rem;
$base-line-height: 1.75;

$spacing-unit: 2rem;
$max-width: 60rem;

$text-color: #41444a;
$background-color: #f7f7f7;
$link-color: #0645ad;

$grey-color: #aaa;
$grey-color-light: color.adjust($grey-color, $lightness: 25%);
$grey-color-dark: color.adjust($grey-color, $lightness: -25%);

// Width of the content area
$on-palm: 600px;
$on-: 800px;
